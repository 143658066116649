import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-league-city-texas.png'
import image0 from "../../images/cities/scale-model-of-space-center-houston-in-league-city-texas.png"
import image1 from "../../images/cities/scale-model-of-kemah-boardwalk-in-league-city-texas.png"
import image2 from "../../images/cities/scale-model-of-texas-parks-&-wildlife-in-league-city-texas.png"
import image3 from "../../images/cities/scale-model-of-johnson-space-center-in-league-city-texas.png"
import image4 from "../../images/cities/scale-model-of-dickinson-railroad-museum-in-league-city-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='League City'
            state='Texas'
            image={image}
            lat='29.5074538'
            lon='-95.09493029999999'
            attractions={ [{"name": "Space Center Houston", "vicinity": "1601 E NASA Pkwy, Houston", "types": ["museum", "point_of_interest", "establishment"], "lat": 29.5518461, "lng": -95.09828920000001}, {"name": "Kemah Boardwalk", "vicinity": "215 Kipp Ave, Kemah", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 29.5472695, "lng": -95.0200347}, {"name": "Texas Parks & Wildlife", "vicinity": "1502 FM 517 Rd E, Dickinson", "types": ["park", "point_of_interest", "establishment"], "lat": 29.45613269999999, "lng": -95.06417920000001}, {"name": "Johnson Space Center", "vicinity": "2101 E NASA Pkwy, Houston", "types": ["point_of_interest", "establishment"], "lat": 29.5593451, "lng": -95.08999799999998}, {"name": "Dickinson Railroad Museum", "vicinity": "218 Farm to Market 517 Rd W, Dickinson", "types": ["museum", "point_of_interest", "establishment"], "lat": 29.446604, "lng": -95.08135700000003}] }
            attractionImages={ {"Space Center Houston":image0,"Kemah Boardwalk":image1,"Texas Parks & Wildlife":image2,"Johnson Space Center":image3,"Dickinson Railroad Museum":image4,} }
       />);
  }
}